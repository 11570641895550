//@@Libs
import Chart from 'react-apexcharts'
import { Card, CardBody, CardHeader, CardTitle, Spinner } from 'reactstrap'
import { useMemo, useState } from 'react'
import startCase from 'lodash/startCase'

//@@ Utils
import {
  getCategoriesParsedMsg,
  getCharacteristicsParsedMsg,
  getHappenedBetweenParsedMsg,
  getParticipatedParsedMsg
} from '@src/views/apps/reports/utils'
import { useGetReportChart } from '@src/views/apps/reports/hooks/useGetReportChart'
import { getParticipationChartOptions } from '@src/views/apps/reports/features/Database/features/Participation/utils'

//@@ Constants
import { LOADING_STATE } from 'constants/api'
import { METRIC_TYPE } from '@src/views/apps/reports/constants'

//@@ Components
import UILoader from '@components/ui-loader'

export const ParticipationChart = ({
  printRef,
  settings,
  queryState,
  churchID,
  changeQueryState,
  setSourcesFromData,
  setCsvRows
}) => {
  //@@ Chart state
  const [type, setType] = useState(settings.showMeCategory === '% of Congregants' ? 'percent' : 'count')

  //@@ Chart data hook
  const {
    state: { chartData, isLoading, isMobile }
  } = useGetReportChart({
    initialData: { labels: [], data: [] },
    dataTransform: (data) => {
      setType(settings.showMeCategory === '% of Congregants' ? 'percent' : 'count')
      return data
    },
    changeQueryState,
    churchID,
    settings,
    queryState,
    type: METRIC_TYPE.participation,
    setSourcesFromData,
    setCsvRows
  })

  //@@ Chart Series
  const series = chartData.data.map((v) => ({ name: startCase(v.sourceName), data: v.data })) ?? []

  //@@ Parse query results
  const title = useMemo(() => {
    return `Showing the ${settings?.showMeCategory ?? '-'} ${getParticipatedParsedMsg(
      settings?.participatedIn
    )} ${getHappenedBetweenParsedMsg(settings.betweenDates)} ${getCategoriesParsedMsg(
      settings.membership,
      settings.individual
    )} ${getCharacteristicsParsedMsg(settings.characteristics)}.`
  }, [queryState])

  return (
    <UILoader loader={<Spinner />} blocking={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>Results</CardTitle>
        </CardHeader>
        <CardBody innerRef={printRef}>
          <CardTitle className="text-primary">{title}</CardTitle>
          <Card>
            <CardBody>
              <div style={{ height: '550px' }}>
                {chartData.data?.length && queryState === LOADING_STATE.FINISHED ? (
                  <Chart
                    options={getParticipationChartOptions({ type, isMobile, labels: chartData?.labels ?? [] })}
                    series={series}
                    type={type === 'percent' ? 'area' : 'bar'}
                    height={550}
                  />
                ) : (
                  !isLoading &&
                  queryState === LOADING_STATE.FINISHED && <h2 className="text-center text-warning">No Data Found</h2>
                )}
              </div>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </UILoader>
  )
}
