const METRIC_TYPE = {
  consolidate: 'consolidate',
  participation: 'participation',
  engagement: 'engagement',
  journey: 'journey',
  time: 'time'
}
const WEB_INSIGHTS_TYPE = {
  metrics: 'metrics',
  funnel: 'funnel',
  cohort: 'cohort',
  individual: 'individual'
}

const METRIC_TYPE_TITLES = {
  [METRIC_TYPE.consolidate]: 'Consolidated Church Metrics Report',
  [METRIC_TYPE.participation]: 'Participation Report',
  [METRIC_TYPE.engagement]: 'Engagement Report',
  [METRIC_TYPE.journey]: 'Journey Report',
  [METRIC_TYPE.time]: 'Time Report'
}

const DATABASE_REQUIRED_FIELDS = {
  [METRIC_TYPE.consolidate]: ['showMeCategory', 'betweenDates'],
  [METRIC_TYPE.participation]: ['showMeCategory', 'participatedIn', 'betweenDates'],
  [METRIC_TYPE.engagement]: ['showMeCategory', 'betweenDates', 'compareDates'],
  [METRIC_TYPE.journey]: [
    'showMeFirstLast',
    'startSource.action',
    'startSource.source',
    'startSource.properties',
    'endSource.action',
    'endSource.source',
    'endSource.properties',
    'betweenDates'
  ],
  [METRIC_TYPE.time]: [
    'showMeCategory',
    'startSource.action',
    'startSource.source',
    'startSource.properties',
    'endSource.action',
    'endSource.source',
    'endSource.properties',
    'betweenDates'
  ]
}

const WEB_INSIGHTS_TITLES = {
  [WEB_INSIGHTS_TYPE.metrics]: 'Web Metrics',
  [WEB_INSIGHTS_TYPE.funnel]: 'Funnel Report',
  [WEB_INSIGHTS_TYPE.cohort]: 'Cohort Report',
  [WEB_INSIGHTS_TYPE.individual]: 'Individual Activity Report'
}

const METRIC_TYPE_DESCRIPTION = {
  [METRIC_TYPE.consolidate]: `The SingleSource Consolidated Church Metrics <b>Report</b> allows you to analyze important church metrics representing the consolidated totals of all church physical and digital locations over a specified time period.`,
  [METRIC_TYPE.participation]: `The SingleSource Participation <b>Report</b> allows you to see the participation level of people in the different areas of your church.`,
  [METRIC_TYPE.engagement]: `The SingleSource Engagement <b>Report</b> allows you to see how engaged people are in the different areas of your church.`,
  [METRIC_TYPE.journey]: `The SingleSource Journey <b>Report</b> allows you to analyze the most common actions that eventually lead to an engagement goal. The first 5 actions taken that eventually led to an engagement action or the last 5 actions taken that eventually led to an engagement action.`,
  [METRIC_TYPE.time]: `The SingleSource Time <b>Report</b> uses different time calculations to allow you to determine the amount of time it takes from the starting event to the engagement goal. Choose between the average, the median (the middle value when a data is ordered from least to greatest) or the mode (the number that occurs most often in the data).`
}

const METRIC_TYPE_DISABLED = {
  [METRIC_TYPE.consolidate]: false,
  [METRIC_TYPE.participation]: false,
  [METRIC_TYPE.engagement]: false,
  [METRIC_TYPE.journey]: false,
  [METRIC_TYPE.time]: false
}

const WEB_INSIGHTS_DISABLED = {
  [WEB_INSIGHTS_TYPE.metrics]: false,
  [WEB_INSIGHTS_TYPE.funnel]: true,
  [WEB_INSIGHTS_TYPE.cohort]: true,
  [WEB_INSIGHTS_TYPE.individual]: true
}
const WEB_INSIGHTS_DESCRIPTION = {
  [WEB_INSIGHTS_TYPE.metrics]: 'Web Metrics show topline charts and graphs from your website traffic.',
  [WEB_INSIGHTS_TYPE.funnel]:
    'The SingleSource Analytics Funnel Report allows you to analyze the most common sequence of actions that eventually leads to a chosen website goal',
  [WEB_INSIGHTS_TYPE.cohort]:
    'The SingleSource Analytics Cohort Report allows you to analyze the behavior of like groups of people on your site, seeing them as an aggregate or each individual in cohort',
  [WEB_INSIGHTS_TYPE.individual]:
    'The SingleSource Individual Activity Report allows you to analyze the activity of an individual, known or unknown, on your website.'
}

const WEB_INSIGHTS_DEFINITION = {
  [WEB_INSIGHTS_TYPE.metrics]: `The SingleSource Analytics data model is comprised of six components:
People, Actions, Referrer, Characteristics, Properties and Time.
Understanding these concepts is vital to being able to analyze the data we
are collecting from your website. A person is our representation of a user
visiting your site, coming to your site from a referrer. A characteristic is any
additional information we know about the person from any data source. A
person can perform actions. Properties help describe a person’s actions.
Time is when a person’s actions are automatically documented by
SingleSource Analytics.
`,
  [WEB_INSIGHTS_TYPE.funnel]: `The SingleSource Analytics data model is comprised of six components:
People, Actions, Referrer, Characteristics, Properties and Time.
Understanding these concepts is vital to being able to analyze the data we
are collecting from your website. A person is our representation of a user
visiting your site, coming to your site from a referrer. A characteristic is any
additional information we know about the person from any data source. A
person can perform actions. Properties help describe a person’s actions.
Time is when a person’s actions are automatically documented by
SingleSource Analytics.
`,
  [WEB_INSIGHTS_TYPE.cohort]: `The SingleSource Analytics data model is comprised of six components:
People, Actions, Referrer, Characteristics, Properties and Time.
Understanding these concepts is vital to being able to analyze the data we
are collecting from your website. A person is our representation of a user
visiting your site, coming to your site from a referrer. A characteristic is any
additional information we know about the person from any data source. A
person can perform actions. Properties help describe a person’s actions.
Time is when a person’s actions are automatically documented by
SingleSource Analytics.
`,
  [WEB_INSIGHTS_TYPE.individual]: `The SingleSource Analytics data model is comprised of six components:
People, Actions, Referrer, Characteristics, Properties and Time.
Understanding these concepts is vital to being able to analyze the data we
are collecting from your website. A person is our representation of a user
visiting your site, coming to your site from a referrer. A characteristic is any
additional information we know about the person from any data source. A
person can perform actions. Properties help describe a person’s actions.
Time is when a person’s actions are automatically documented by
SingleSource Analytics.
`
}

const DATABASE_METRICS_LIST = Object.values(METRIC_TYPE).map((metric) => ({
  id: metric,
  title: METRIC_TYPE_TITLES[metric],
  description: METRIC_TYPE_DESCRIPTION[metric],
  disabled: METRIC_TYPE_DISABLED[metric]
}))
const WEB_INSIGHTS_LIST = Object.values(WEB_INSIGHTS_TYPE).map((report) => ({
  id: report,
  title: WEB_INSIGHTS_TITLES[report],
  description: WEB_INSIGHTS_DESCRIPTION[report],
  disabled: WEB_INSIGHTS_DISABLED[report]
}))

const DATABASE_ACTION_TYPE = {
  query: 'query',
  details: 'details',
  save: 'save',
  print: 'print',
  export: 'export',
  regularly: 'regularly'
}

const DATABASE_ACTION_LABEL = {
  [DATABASE_ACTION_TYPE.query]: 'Run Query',
  [DATABASE_ACTION_TYPE.details]: 'Show Details',
  [DATABASE_ACTION_TYPE.save]: 'Save',
  [DATABASE_ACTION_TYPE.print]: 'Print',
  [DATABASE_ACTION_TYPE.export]: 'Export',
  [DATABASE_ACTION_TYPE.regularly]: 'Run Regularly'
}

const CHARACTERISTICS_OPTIONS = [
  { value: 'gender', label: 'Gender' },
  { value: 'maritalStatuses', label: 'Marital Status' },
  { value: 'age', label: 'Age' }
]

const TOTAL_OPTIONS = [
  { label: 'Dollars Collected During', value: 'Dollars Collected During' },
  { label: 'Number of People Attending', value: 'Number of People Attending' }
]

const CONGREGANTS_OPTIONS = [
  { value: '% of Congregants', label: '% of Congregants' },
  { value: '# of Congregants', label: '# of Congregants' }
]

const ENGAGEMENT_VALUES = {
  bible_study: 'Bible Study Engagement',
  cell_group: 'Cell Group Engagement',
  giving: 'Giving Engagement',
  ministry: 'Ministry Engagement',
  social: 'Social Engagement'
}

const ENGAGEMENT_OPTIONS = [
  { label: 'Bible Study Engagement', value: ENGAGEMENT_VALUES.bible_study },
  { label: 'Cell Group Engagement', value: ENGAGEMENT_VALUES.cell_group },
  { label: 'Giving Engagement', value: ENGAGEMENT_VALUES.giving },
  { label: 'Ministry Engagement', value: ENGAGEMENT_VALUES.ministry },
  { label: 'Social Engagement', value: ENGAGEMENT_VALUES.social }
]

const GENERAL_MEMBERSHIP_VALUES = ['All', 'Congregation', 'All Known', 'All Unknown']

const DISABLED_PRINT_WARNING = 'Please Run Query And Get Results Before'

const SHOW_FIRST_LAST_OPTIONS = [
  { label: 'First 5 Actions', value: 'First' },
  { label: 'Last 5 Actions', value: 'Last' }
]

const SHOW_ME_OPTIONS = [
  { label: 'Average Time', value: 'Average' },
  { label: 'Median Time', value: 'Median' },
  { label: 'Mode Time', value: 'Mode' }
]

//@@ Chart colors
const CHART_COLORS = {
  series1: '#2b9bf4',
  series2: '#FFA1A1',
  series3: '#00d4bd',
  series4: '#826bf8',
  series5: '#ffe700',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  darkBlueColor: '#0B6BB4FF',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  warningColorShade: '#ffbd1f',
  warningLightColor: '#FDAC34',
  successColorShade: '#28dac6',
  primaryColorShade: '#836AF9',
  infoColorShade: '#299AFF',
  yellowColor: '#ffe800',
  greyColor: '#4F5D70'
}

//@@ Report types with sources in data
const SOURCES_BY_DATA_REPORTS = [METRIC_TYPE.participation, METRIC_TYPE.consolidate]

//@@ Csv export header
const CSV_EXPORT_HEADER = ['Date', 'Source', 'Total', 'Average']

//@@ Csv export types
const CSV_EXPORT_TYPES = [METRIC_TYPE.consolidate, METRIC_TYPE.participation, METRIC_TYPE.engagement]

export {
  METRIC_TYPE,
  METRIC_TYPE_TITLES,
  METRIC_TYPE_DESCRIPTION,
  METRIC_TYPE_DISABLED,
  DATABASE_METRICS_LIST,
  DATABASE_ACTION_LABEL,
  DATABASE_ACTION_TYPE,
  TOTAL_OPTIONS,
  WEB_INSIGHTS_DESCRIPTION,
  WEB_INSIGHTS_TITLES,
  WEB_INSIGHTS_TYPE,
  WEB_INSIGHTS_DEFINITION,
  WEB_INSIGHTS_DISABLED,
  CHARACTERISTICS_OPTIONS,
  GENERAL_MEMBERSHIP_VALUES,
  ENGAGEMENT_VALUES,
  ENGAGEMENT_OPTIONS,
  CONGREGANTS_OPTIONS,
  WEB_INSIGHTS_LIST,
  DATABASE_REQUIRED_FIELDS,
  DISABLED_PRINT_WARNING,
  SHOW_FIRST_LAST_OPTIONS,
  SHOW_ME_OPTIONS,
  CHART_COLORS,
  SOURCES_BY_DATA_REPORTS,
  CSV_EXPORT_HEADER,
  CSV_EXPORT_TYPES
}
