//@@ Libs
import { useMemo } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Spinner } from 'reactstrap'
import Chart from 'react-apexcharts'
import moment from 'moment'

//@@ Utils
import {
  getCategoriesParsedMsg,
  getCharacteristicsParsedMsg,
  getHappenedBetweenParsedMsg
} from '@src/views/apps/reports/utils'
import { useGetReportChart } from '@src/views/apps/reports/hooks/useGetReportChart'
import { getBetweenChartOptions, getCompareChartOptions } from '../utils'

//@@ Constants
import { LOADING_STATE } from 'constants/api'
import { ENGAGEMENT_VALUES } from '@src/views/apps/reports/constants'
import { ENGAGEMENT_CHART_DATE_RESTRICTION } from '@src/views/apps/reports/features/Database/features/Engagement/constants'

//@@ Components
import UILoader from '@components/ui-loader'

export const EngagementChart = ({ printRef, settings, queryState, churchID, changeQueryState, type, setCsvRows }) => {
  //@@ Chart state
  const {
    state: { isLoading, chartData, isMobile }
  } = useGetReportChart({
    initialData: {
      period: [],
      labels: [],
      comparePeriod: [],
      compareLabels: []
    },
    dataTransform: (data) => data,
    queryState,
    churchID,
    changeQueryState,
    type,
    settings,
    setCsvRows
  })

  //@@ Chart Series
  const series = (type) => {
    return type === 'between'
      ? [
          {
            name: 'Between',
            data: chartData.period
          }
        ]
      : [
          {
            name: 'Compare To',
            data: chartData.comparePeriod
          }
        ]
  }

  //@@ Parse query results
  const title = useMemo(() => {
    return `Showing the ${settings?.showMeCategory ?? ''} ${getCategoriesParsedMsg(
      settings.membership,
      settings.individual
    )} ${getCharacteristicsParsedMsg(settings.characteristics)} ${getHappenedBetweenParsedMsg(
      settings.betweenDates
    )}${getHappenedBetweenParsedMsg(settings.compareDates, ', compare to')}.`
  }, [queryState])

  const isLessPeriod = (period) => {
    if (settings.showMeCategory !== ENGAGEMENT_VALUES.giving) {
      return false
    }

    const start = moment(period[0])
    const end = moment(period[1])
    const diff = end.diff(start, 'month')

    return diff < 3
  }

  return (
    <UILoader loader={<Spinner />} blocking={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>Results</CardTitle>
        </CardHeader>
        <CardBody innerRef={printRef}>
          <CardTitle className="text-primary">{title}</CardTitle>
          <Card>
            <CardHeader>
              <CardTitle>Between Dates</CardTitle>
            </CardHeader>
            <CardBody>
              {!!chartData.period.length && queryState === LOADING_STATE.FINISHED ? (
                <>
                  {!isLessPeriod(settings.betweenDates) ? (
                    <Chart
                      options={getBetweenChartOptions({ isMobile, labels: chartData.labels })}
                      series={series('between')}
                      type="bar"
                      height={400}
                    />
                  ) : (
                    <h2 className="text-center text-warning">{ENGAGEMENT_CHART_DATE_RESTRICTION}</h2>
                  )}
                </>
              ) : (
                !isLoading &&
                queryState === LOADING_STATE.FINISHED && <h2 className="text-center text-warning">No Data Found</h2>
              )}
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Compare To Dates</CardTitle>
            </CardHeader>
            <CardBody>
              {!!chartData.comparePeriod.length && queryState === LOADING_STATE.FINISHED ? (
                <>
                  {!isLessPeriod(settings.compareDates) ? (
                    <Chart
                      options={getCompareChartOptions({ isMobile, labels: chartData.compareLabels })}
                      series={series('compare')}
                      type="bar"
                      height={400}
                    />
                  ) : (
                    <h2 className="text-center text-warning">{ENGAGEMENT_CHART_DATE_RESTRICTION}</h2>
                  )}
                </>
              ) : (
                !isLoading &&
                queryState === LOADING_STATE.FINISHED && <h2 className="text-center text-warning">No Data Found</h2>
              )}
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </UILoader>
  )
}
