//@@ Libs
import {useEffect, useState} from 'react'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

//@@ Hooks
import {useLayout} from '@hooks/useLayout'

//@@ Constants
import {LOADING_STATE} from 'constants/api'
import {api} from '@src/services/api'
import {SOURCES_BY_DATA_REPORTS} from '@src/views/apps/reports/constants'
import {LAYOUT_TYPE} from '@src/constants'
import {getJWTToken} from "@src/utility/auth/tokens"

export const useGetReportChart = ({
                                      initialData,
                                      dataTransform,
                                      type,
                                      settings,
                                      churchID,
                                      queryState,
                                      changeQueryState,
                                      setSourcesFromData,
                                      setCsvRows
                                  }) => {
    //@@ Chart state
    const [chartData, setChartData] = useState(initialData)
    const [isLoading, setIsLoading] = useState(false)

    //@@ Layout
    const {layout} = useLayout()

    //@@ Load chart data on run query
    useEffect(() => {
        if (queryState === LOADING_STATE.FINISHED) {
            return
        }
        let count = 1

        setIsLoading(true)
        const getReportChart = () => {
            getJWTToken().then(token => api
                .post('get-report-chart', {type, settings, churchID},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }))
                .then(({data}) => {
                    setChartData(typeof data !== 'number' && isEmpty(data) ? initialData : dataTransform(data))
                    setIsLoading(false)
                    changeQueryState(LOADING_STATE.FINISHED)

                    if (SOURCES_BY_DATA_REPORTS.includes(type) && !isEmpty(data)) {
                        setSourcesFromData(data?.data?.map(({sourceName}) => startCase(sourceName)))
                    }
                    if (data) {
                        if (type === 'consolidate') {
                            const csvData = [['Date', 'Source', 'Total', 'Average']]
                            data.data.forEach((val, i) => {
                                val.total.forEach((v1, i1) => {
                                    csvData.push([data.labels[i1], val['sourceName'], v1, val.average[i1]])
                                })
                            })
                            setCsvRows(csvData)
                        } else if (type === 'participation') {
                            const csvData = [['Date', 'Source', 'Percentage']]
                            data.data.forEach((val) => {
                                val.data.forEach((v1, i1) => {
                                    csvData.push([data.labels[i1], val['sourceName'], v1.toFixed(2)])
                                })
                            })
                            setCsvRows(csvData)
                        } else {
                            setCsvRows(data.people_list.map(({
                                                                 first_name,
                                                                 last_name,
                                                                 email
                                                             }) => [first_name, last_name, email]))
                        }
                    }
                    count = 0
                })
                .catch((e) => {
                    if (e?.code === 'ERR_NETWORK' && !e?.response?.data && count < 16 && count !== 0) {
                        count++
                        getReportChart()
                    } else {
                        setIsLoading(false)
                        changeQueryState(LOADING_STATE.FINISHED)
                        count = 0
                    }
                })
        }
        getReportChart()

        return () => {
            count = 0
        }
    }, [queryState])
    return {state: {chartData, isLoading, isMobile: layout === LAYOUT_TYPE.vertical}}
}
