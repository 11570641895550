//@@ Libs
import { Card, CardBody, CardHeader, CardTitle, Spinner } from 'reactstrap'
import { useMemo } from 'react'
import Chart from 'react-apexcharts'
import startCase from 'lodash/startCase'

//@@ Utils
import {
  getCategoriesParsedMsg,
  getCharacteristicsParsedMsg,
  getHappenedBetweenParsedMsg,
  getUniqueEventParsedMsg
} from '@src/views/apps/reports/utils'
import { useGetReportChart } from '@src/views/apps/reports/hooks/useGetReportChart'
import {
  getAverageChartOptions,
  getTotalChartOptions
} from '@src/views/apps/reports/features/Database/features/Consolidate/utils'

//@@ Constants
import { LOADING_STATE } from 'constants/api'

//@@ Components
import UILoader from '@components/ui-loader'

//@@ Styles
import '@styles/react/libs/charts/apex-charts.scss'

export const ConsolidateChart = ({
  printRef,
  settings,
  queryState,
  changeQueryState,
  churchID,
  type,
  setSourcesFromData,
  setCsvRows
}) => {
  //@@ Chart data hook
  const {
    state: { chartData, isLoading, isMobile }
  } = useGetReportChart({
    initialData: { total: [], average: [], labels: [] },
    dataTransform: (data) => ({
      labels: data.labels,
      total: data.data.map(({ sourceName, total }) => ({ sourceName, data: total })),
      average: data.data.map(({ sourceName, average }) => ({ sourceName, data: average }))
    }),
    changeQueryState,
    churchID,
    type,
    settings,
    queryState,
    setSourcesFromData,
    setCsvRows
  })

  //@@ Chart Series
  const totalSeries = chartData.total?.map((v) => ({ name: startCase(v.sourceName), data: v.data })) ?? []
  const averageSeries = chartData.average?.map((v) => ({ name: startCase(v.sourceName), data: v.data })) ?? []

  //@@ Parse query results
  const title = useMemo(() => {
    return `Showing the ${getUniqueEventParsedMsg(
      settings.showMeCategory,
      settings.uniqueEvent
    )} ${getHappenedBetweenParsedMsg(settings.betweenDates)} ${getCategoriesParsedMsg(
      settings.membership,
      settings.individual
    )} ${getCharacteristicsParsedMsg(settings.characteristics)}.`
  }, [queryState])

  return (
    <UILoader loader={<Spinner />} blocking={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>Results</CardTitle>
        </CardHeader>
        <CardBody innerRef={printRef}>
          <CardTitle className="text-primary">{title}</CardTitle>
          <Card>
            <CardBody>
              <CardTitle className="text-center">Total</CardTitle>
              <div style={{ height: '450px' }}>
                {chartData.total?.length && queryState === LOADING_STATE.FINISHED ? (
                  <Chart
                    options={getTotalChartOptions({ labels: chartData?.labels, category: settings?.showMeCategory })}
                    series={totalSeries}
                    type="area"
                    height={400}
                  />
                ) : (
                  !isLoading &&
                  queryState === LOADING_STATE.FINISHED && <h2 className="text-center text-warning">No Data Found</h2>
                )}
              </div>
            </CardBody>
            <CardBody>
              <CardTitle className="text-center">Average</CardTitle>
              <div style={{ height: '450px' }}>
                {chartData.average?.length && queryState === LOADING_STATE.FINISHED ? (
                  <Chart
                    options={getAverageChartOptions({
                      labels: chartData?.labels,
                      category: settings?.showMeCategory,
                      isMobile
                    })}
                    series={averageSeries}
                    type="bar"
                    height={400}
                  />
                ) : (
                  !isLoading &&
                  queryState === LOADING_STATE.FINISHED && <h2 className="text-center text-warning">No Data Found</h2>
                )}
              </div>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </UILoader>
  )
}
