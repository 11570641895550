//@@ Libs
import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, ListGroup, ListGroupItem, Spinner } from 'reactstrap'
import { Bookmark, BookOpen, DollarSign, Heart, Users, Instagram } from 'react-feather'

//@@ Hooks
import { useChurchEngagement } from '@components/apps/EngagementLevels/hooks/useChurchEngagement'
import { useProfileEngagement } from '@components/apps/EngagementLevels/hooks/useProfileEngagement'

//@@ Components
import UILoader from '@src/@core/components/ui-loader/index'

export const EngagementLevelsCard = ({ churchID, profile, className = '', startedAt }) => {

  const { isLoading, levels } = profile
    ? useProfileEngagement({ profile, startedAt })
    : useChurchEngagement({ churchID })

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>ENGAGEMENT LEVELS</CardTitle>
      </CardHeader>
      <CardBody>
        <UILoader loader={<Spinner />} blocking={isLoading}>
          <ListGroup>
            {/*<ListGroupItem className="d-flex">*/}
            {/*  <span className="me-1">🤝</span>*/}
            {/*  <span>Membership Engagement Level - 100%</span>*/}
            {/*</ListGroupItem>*/}
            <ListGroupItem className="d-flex align-items-center">
              <span className="me-1">
                <Bookmark size={26} className="text-success" />
              </span>
              <span>{`Attendance Engagement Level - ${levels?.attendance?.toFixed() ?? '0'}%`}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex align-items-center">
              <span className="me-1">
                <Users size={26} className="text-success" />
              </span>
              <span>{`Cell Group Engagement Level - ${levels?.cell_group?.toFixed() ?? '0'}%`}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex align-items-center">
              <span className="me-1">
                <BookOpen size={26} className="text-success" />
              </span>
              <span>{`Bible Study Engagement Level - ${levels?.bible_study?.toFixed() ?? '0'}%`}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex align-items-center">
              <span className="me-1">
                <Heart size={26} className="text-success" />
              </span>
              <span>{`Ministry Engagement Level - ${levels?.ministry?.toFixed() ?? '0'}%`}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex align-items-center">
              <span className="me-1">
                <DollarSign size={26} className="text-success" />
              </span>
              <span>{`Giving Engagement Level - ${levels?.giving?.toFixed(0) ?? '0'}%`}</span>
            </ListGroupItem>
            <ListGroupItem className="d-flex align-items-center">
              <span className="me-1">
                <Instagram size={26} className="text-success" />
              </span>
              <span>Social Media Engagement Level - {levels?.social?.toFixed() ?? '0'}%</span>
            </ListGroupItem>
          </ListGroup>
        </UILoader>
      </CardBody>
    </Card>
  )
}
